<template>
	<div class="pt-4">
	  <div class="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
		<b-pagination
		  v-model="localPage"
		  :total-rows="totalRows"
		  :per-page="perPage"
		  first-text="Primeiro"
		  prev-text="Anterior"
		  next-text="Próximo"
		  last-text="Última"
		  @input="changePage()"
		  class="mb-2 mb-md-0"
		>
		</b-pagination>

		<div class="d-flex align-items-center">
		  <span class="px-2">Itens por página:</span>
		  <b-dropdown id="perPage" :text="perPage.toString()">
			<b-dropdown-item
			  v-for="option in optionsPerPage"
			  :key="option.value"
			  @click="changeItemsPerPage(option.value)"
			>
			  {{ option.text }}
			</b-dropdown-item>
		  </b-dropdown>
		</div>
	  </div>

	  <div class="table-responsive">
		<b-table
		  id="my-table"
		  striped
		  :items="linhas"
		  :fields="fields"
		  :per-page="perPage"
		  @sort-changed="onSortChanged"
		  class="text-center"
		>
		<template v-slot:cell(confere)="row">
			<component
				:is="row.item.confere.icon"
				:style="{ color: row.item.confere.color }"/>
		  </template>

		  <template v-slot:cell(icon)="row">
			<component :is="row.item.icon.icon" />
		  </template>

		  <template v-slot:cell(observacao)="data">
			<QuestionIcon :id="'observation-toast' + data.index" />
			<b-tooltip :target="'observation-toast' + data.index" placement="left" :disabled="!data.item.observacao">
			  {{ data.item.observacao }}
			</b-tooltip>
		  </template>

		  <template #head()="data">
			<div
			  class="d-flex justify-content-center align-items-center head-cell"
			  @click="toggleSort(data.field.orderKey)"
			>
			  {{ data.label }}
			  <span
				v-if="data.field.customSortable"
				style="cursor: pointer"
			  >
				<component :is="getSortIcon(data.field.orderKey)" />
			  </span>
			</div>
		  </template>
		</b-table>
	  </div>
	</div>
  </template>

<script>
	export default {
		name: "DataTable",
		props: {
			fields: {
				type: Array,
				required: true
			},
			linhas: {
				type: Array,
				required: true
			},
			totalRows: {
				type: Number,
				required: true
			},
			currentPage: {
				type: Number,
				required: true
			},
			currentSort: {
				type: Object,
				required: true
			},
			itemsPerPage: {
				type: Number,
				required: true
			}
		},
		data () {
			return {
				perPage: this.itemsPerPage,
				localPage: this.currentPage,
				lestPage: 0,
				optionsPerPage: [
					{ value: 25, text: 25 },
					{ value: 50, text: 50 },
					{ value: 75, text: 75 },
					{ value: 100, text: 100 }
				],
				localSort: this.currentSort
			};
		},
		methods: {
			search () {
				this.q = parseQuery(this.query);
				this.emitState();
			},

			toggleSort (orderKey) {
				if (this.localSort.by === orderKey) {
					if (this.localSort.reverse) {
						this.localSort.reverse = false;
					} else {
						this.localSort.reverse = true;
					}
				} else {
					this.localSort.by = orderKey;
					this.localSort.reverse = true;
				}

				this.$emit("change-order", this.localSort);
			},

			getSortIcon (orderKey) {
				if (this.localSort.by !== orderKey) {
					return "";
				}
				return this.localSort.reverse ? "ChevronDownIcon" : "ChevronUpIcon";
			},

			changePage () {
				this.$emit("change-page", this.localPage);
			},

			changeItemsPerPage (value) {
				this.perPage = value;
				this.$emit("items-per-Page", this.perPage );
			}
		}
	};
</script>

<style scoped>
	.head-cell {
		height: 50px;
	}
</style>
